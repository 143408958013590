<!-- 提现记录 -->
<template>
  <div class="warp">
    <el-card class="warp-content">
      <!-- 头部提现 -->
      <el-row style="width: 100%; margin: 0 0 15px 0">
        <el-col :span="12" style="height: 50px">
          <span class="fontNav">{{ selfOrgName }}</span>
        </el-col>
        <el-col :span="12" style="text-align: right">
          <el-button
            type="primary"
            size="small"
            @click="WithdrawalClick"
            v-if="userLevel === 1"
            >提现</el-button
          >
        </el-col>
      </el-row>

      <!-- 描述列表区域 -->
      <el-descriptions
        class="descriptions-class"
        :title="descriptionTitle"
        :column="3"
        size="large"
        border
      >
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">所属平台商</div>
          </template>
          <span>{{ orgName }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">商户号</div>
          </template>
          <span>{{ merchantId }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">应用名称</div>
          </template>
          <span>{{ userApp }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">累计金额</div>
          </template>
          <el-tag type="success" size="small"
            >￥ {{ decimalClick(orderBalance) }}</el-tag
          >
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">今日收入</div>
          </template>
          <el-tag type="success" size="small"
            >￥ {{ decimalClick(todayOrderBalance) }}</el-tag
          >
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">可用余额</div>
          </template>
          <el-tag size="small">￥{{ decimalClick(downBalance) }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">待审核</div>
          </template>
          <el-tag size="small">￥{{ decimalClick(processBalance) }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">结算时间</div>
          </template>
          {{ paymentTime }}
        </el-descriptions-item>
      </el-descriptions>

      <!-- 搜素区域 -->
      <div class="page-select">
        <el-row style="width: 100%; margin: 0 0 15px 0">
          <el-col :span="24" style="text-align: right">
            <el-input
              placeholder="请输入订单编号"
              class="search-input"
              prefix-icon="search"
              size="small"
              @input="searchInput"
              v-model="detailIdValue"
            >
            </el-input>
            <el-input
              placeholder="请输入申商户"
              class="search-input"
              prefix-icon="search"
              size="small"
              @input="searchInput"
              v-model="cardDealValue"
            >
            </el-input>
            <el-select
              clearable
              class="search-input"
              placeholder="请选择订单状态"
              size="small"
              v-model="statusValue"
              @change="handleStateChange"
            >
              <el-option
                v-for="item in deviceAgencyList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                {{ item.label }}
              </el-option>
            </el-select>
            <el-button type="primary" size="small" @click="searchClick"
              >搜索</el-button
            >
          </el-col>
        </el-row>
      </div>

      <!-- 表格区域 -->
      <div class="page-container">
        <el-table
          ref="tableRef"
          :data="tableData"
          style="width: 100%"
          max-height="630px"
          :header-cell-style="{ background: '#F2F4F8' }"
          @selection-change="handleSelectionChange"
        >
          <!--          <el-table-column type="selection" align="center" width="80" />-->
          <el-table-column
            v-for="(item, index) in tableColumnData"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            align="center"
          >
            <template #default="{ row }" v-if="item.prop === 'status'">
              <span :class="sizeClass(row)">{{ statusSize(row) }}</span>
            </template>
          </el-table-column>

          <!-- <el-table-column label="操作" align="center">
            <template #default="{ row }">
              <el-button type="text" @click="editClick(row)">通过</el-button>
              <el-button type="text" @click="editClick(row)">不通过</el-button>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
      <!-- 分页区域 -->
      <div class="page-foot">
        <page-nation
          :total="total"
          @pageChange="handleSizeChange"
          @currentChange="handleCurrentChange"
        ></page-nation>
      </div>
      <!--审核提现金额-->
      <el-dialog
        v-model="dialogVisible"
        width="40%"
        :title="dialogTitleName"
        :close-on-click-modal="false"
        @closed="resetForm"
      >
        <el-form
          label-width="95px"
          :inline="true"
          :model="editFormData"
          :rules="editDeviceRules"
          ref="editRuleFormRef"
        >
          <el-row :gutter="20">
            <el-col :span="22">
              <el-form-item label="昵称：" prop="deviceMac">
                <el-input
                  placeholder="请输入昵称"
                  disabled
                  v-model="editFormData.deviceMac"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="22">
              <el-form-item label="微信ID：" prop="deviceVersion">
                <el-input
                  placeholder="请输入微信ID"
                  disabled
                  v-model="editFormData.deviceVersion"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="22">
              <el-form-item label="状态：" prop="deviceStatus">
                <el-select
                  v-model="editFormData.deviceStatus"
                  placeholder="请选择状态"
                  class="search-input-class"
                  @change="jointFn"
                >
                  <el-option
                    v-for="item in deviceStatusOptions"
                    :key="item.v"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="resetEditForm">取消 </el-button>
            <el-button
              type="primary"
              @click="submitEditForm('allocateDeviceRuleFormRef')"
              >保存
            </el-button>
          </span>
        </template>
      </el-dialog>
      <!-- 提现弹框 -->
      <el-dialog
        width="30%"
        title="商户余额提现"
        v-model="tixianVisible"
        @closed="resetWithdraForm"
      >
        <el-form
          label-width="120px"
          :inline="true"
          :model="FormData"
          :rules="rules"
          ref="formRef"
        >
          <el-row :gutter="20">
            <el-col :span="22">
              <el-form-item label="商户名称：">
                <span>{{ selfOrgName }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="可提现金额(元)：">
                <span>{{ downBalance }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="本次提现(元)：" prop="money">
                <el-input-number
                  v-model="FormData.money"
                  :min="1"
                  :max="20000"
                  size="small"
                  @change="handleChange"
                />
                <el-button class="wholeClass" size="small" @click="wholeClick"
                  >全部提现</el-button
                >
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="收款账户：" prop="phone">
                <el-radio-group v-model="FormData.phone">
                  <el-radio
                    v-for="item in withdrawalAccount"
                    :key="item.value"
                    :label="item.value"
                    >{{ item.label }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <template #footer>
          <span class="zuClass"
            >注：单日提现次数和提现额度与商户号绑定设置,最低提现1元</span
          >
          <span class="dialog-footer">
            <el-button size="small" @click="resetWithdraForm">取消 </el-button>
            <el-button
              size="small"
              type="primary"
              @click="submitWithdraForm('formRef')"
              >提现
            </el-button>
          </span>
        </template>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue-demi";
import pageNation from "@/components/base/pageNation.vue";
import {
  selectPagesApi,
  selectAllApi,
  transferDetailApi,
} from "@/api/financeManagement/financeManagement.js";
import { requestApi } from "@/utils/requestApi";
import { debounces } from "@/common/utils";
export default {
  name: "AccountSearch",
  components: { pageNation },
  setup() {
    const { proxy } = getCurrentInstance();
    const data = reactive({
      typeVal: null, // 商户是否停用
      selfOrgName: null, // 商户名称
      orgName: null, //所属商户
      merchantId: null, // 商户号id
      merchantName: null, //商户号
      userApp: null, //应用名称
      orderBalance: 0, // 累计金额
      todayOrderBalance: 0, //当日累计收入
      downBalance: 0, // 可用余额
      processBalance: 0, //待审核金额
      paymentTime: null, //结算时间
      financesBalance: 0, // 提现金额
      countMoney: 0, // 充值剩余额度
      giftAmount: 0, // 赠送总额
      tixianVisible: false, // 提现弹框
      FormData: {
        money: null, // 提现的金额
        phone: "", // 收款账号手机号
        merchantId: null, // 提现 商户号id
        time: "", // 当天时间
        userName: null, // 用户名称
        merchantName: null, // 商户名称
      }, //
      rules: {
        phone: {
          required: true,
          message: "请选择收款账号...",
          trigger: ["blur", "change"],
        },
      },
      // 提现账户
      withdrawalAccount: [],

      detailIdValue: null, //订单编号
      cardDealValue: null, //申请商户
      statusValue: null, //订单状态
      tableData: [], // 表格数据
      total: 0,
      pageSize: 10,
      currentPage: 1,
      userInfo: null,
      userLevel: 0, //用户等级
      selection: [],
      disabled: true,
      formData: {
        alarTime: [], //起始时间
      },
      tableColumnData: [
        // {
        //   prop: "deviceMac",
        //   label: "详情",
        // },
        {
          prop: "detailId",
          label: "订单编号",
        },
        {
          prop: "createTime",
          label: "申请时间",
        },
        {
          prop: "paymentTime",
          label: "付款时间",
        },
        // {
        //   prop: "type",
        //   label: "账户类型",
        // },
        {
          prop: "cardDeal",
          label: "申请商户名称",
        },
        {
          prop: "money",
          label: "提现金额(元)",
        },
        {
          prop: "status",
          label: "订单状态",
        },
        {
          prop: "remake",
          label: "备注",
        },
        // {
        //   prop: "deviceChargnt",
        //   label: "收费方案",
        // },
      ],
      deviceAgencyList: [
        {
          label: "提现成功",
          value: 0,
        },
        {
          label: "审核中",
          value: 1,
        },
        {
          label: "提现失败",
          value: 2,
        },
      ],
    });
    onMounted(() => {
      proxy.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      proxy.merchantId = proxy.userInfo.chargingMerchant.merchantId;
      proxy.$nextTick(() => {
        proxy.userLevel = proxy.userInfo.chargingMerchant.level;
        proxy.merchantName = proxy.userInfo.chargingMerchant.merchantName;
      });
      methods.selectPages();
      methods.selectPagesAll();
    });
    // 节流搜索按钮
    const searchInput = debounces(() => {
      methods.selectPagesAll();
    }, 500);
    const methods = {
      isDecimal(strValue) { 
        var objRegExp= /^(([0-9]|([1-9][0-9]{0,9}))((\\.[0-9]{1,2})?))$/;
        return objRegExp.test(strValue); 
      },
      // 商户余额查询
      async selectPages() {
        await requestApi(
          () => {
            const params = {
              merchantId: proxy.merchantId,
            };
            return selectPagesApi(params);
          },
          (res) => {
            if (res.code === 200) {
              proxy.selfOrgName = res.data.selfOrgName;
              proxy.orgName = res.data.orgName;
              // proxy.merchantId = res.data.merchantId;
              proxy.userApp = res.data.userApp;
              proxy.orderBalance = res.data.orderBalance;
              proxy.todayOrderBalance = res.data.todayOrderBalance;
              proxy.downBalance = res.data.downBalance;
              proxy.processBalance = res.data.processBalance;
              proxy.paymentTime = res.data.paymentTime;
              proxy.countMoney = res.data.countMoney; //充值剩余额度
              proxy.giftAmount = res.data.giftAmount; // 赠送总额
            }
          }
        );
      },
      // 点击编辑
      editClick(row) {
        console.log(row);
        data.dialogVisible = true;
        data.editFormData = {
          deviceMac: row.deviceMac,
          deviceVersion: row.deviceVersion,
          deviceStatus: row.deviceStatus,
        };
      },
      // 编辑确认
      submitEditForm() {
        data.dialogVisible = false;
      },
      // 取消
      resetWithdraForm() {
        proxy.tixianVisible = false;
        proxy.withdrawalAccount = [];
        proxy.$refs.formRef.resetFields();
      },
      // 提现按钮
      WithdrawalClick() {
        proxy.tixianVisible = true; // 打开提现弹框
        proxy.FormData.money = 1; // 给提现默认赋值1元
        proxy.FormData.merchantName =
          proxy.userInfo.chargingMerchant.merchantName; // 商户名称
        proxy.FormData.userName =
          proxy.userInfo.chargingMerchant.merchantLinkman; // 商户申请人
        proxy.FormData.merchantId = proxy.merchantId; // 商户ID
        const myDate = new Date();
        const myYear = myDate.getFullYear(); //获取完整的年份(4位,1970-????)
        const myMonth = myDate.getMonth() + 1; //获取当前月份(0-11,0代表1月)
        const myToday = myDate.getDate(); //获取当前日(1-31)
        proxy.FormData.time =
          myYear +
          "-" +
          methods.fillZero(myMonth) +
          "-" +
          methods.fillZero(myToday); //当天时间
        proxy.withdrawalAccount.push({
          value: proxy.userInfo.chargingMerchant.merchantLinkphone,
          label: proxy.userInfo.chargingMerchant.merchantName,
        });
      },
      fillZero(time) {
        if (time < 10) {
          return "0" + time;
        } else {
          return time;
        }
      },
      // 输入提现按钮
      handleChange(num) {
        // console.log('我触发了');
        // setTimeout(() => {
        //   // if (!Number.isInteger(value)) {
        //   //   callback(new Error('请输入数字值'));
        //   // } else {
        //   //   if (!isDecimal) {
        //   //     // callback(new Error('必须年满18岁'));
        //   //     console.log('错了');
        //   //   } else {
        //   //     proxy.FormData.money = num;
        //   //   }
        //   // }
          
        // }, 1000);
        if(!proxy.isDecimal(num)) {
            // console.log('错了');
            proxy.$nextTick(() => {
              proxy.FormData.money = 1
            })
          } else {
            proxy.FormData.money = num;
            // console.log('对了', num);
          }
      },
      // 全部提现
      wholeClick() {
        proxy.FormData.money = proxy.downBalance;
        // proxy.$msgbox
        //   .confirm("确认是否全部提现?", "提现", {
        //     closeOnClickModal: false,
        //     confirmButtonText: "确定",
        //     cancelButtonText: "取消",
        //     type: "warning",
        //   })
        //   .then(async () => {
        //     methods.submitEditForm(formName);
        //   })
        //   .catch({});
      },
      // 提现确定
      submitWithdraForm(formName) {
        proxy.$refs[formName].validate(async (valid) => {
          if (valid) {
            // await methods.WithdrawalAll(proxy.FormData);
            if (
              data.downBalance > 1 &&
              data.downBalance >= proxy.FormData.money
            ) {
              await methods.WithdrawalAll(proxy.FormData);
            } else if (
              data.downBalance > 1 &&
              data.downBalance < proxy.FormData.money
            ) {
              proxy.$notify({
                title: "提示",
                message: "提现额度不能大于商户余额!",
                type: "warning",
                duration: 2000,
              });
            } else {
              proxy.$notify({
                title: "提示",
                message: "商户余额不足...",
                type: "warning",
                duration: 2000,
              });
            }
          }
        });
      },
      // 搜索
      searchClick() {
        methods.selectPagesAll();
      },
      // 表格分页 改变每页数量时触发
      handleSizeChange(val) {
        proxy.pageSize = val;
        methods.selectPagesAll();
      },
      handleStateChange(str) {
        console.log(str);
        if (typeof str == "string" && str.constructor == String) {
          data.statusValue = null;
          methods.selectPagesAll();
        } else {
          methods.selectPagesAll();
        }
      },
      // 表格分页 改变页数时触发
      handleCurrentChange(val) {
        proxy.currentPage = val;
        methods.selectPagesAll();
      },
      // 提现记录查询
      async selectPagesAll() {
        await requestApi(
          () => {
            const params = {
              current: data.currentPage,
              size: data.pageSize,
              status: data.statusValue,
              cardDeal: data.cardDealValue,
              detailId: data.detailIdValue,
              merchantId: proxy.merchantId,
            };
            return selectAllApi(params);
          },
          (res) => {
            if (res.code === 200) {
              // proxy.tableData = res.data.records;
              data.tableData = res.data.records.map((it) => {
                return {
                  detailId: it.detailId,
                  createTime: it.createTime,
                  paymentTime: it.paymentTime,
                  type: it.type,
                  cardDeal: it.cardDeal,
                  money: it.money,
                  status: it.status,
                  remake: it.remake,
                };
              });
              data.currentPage = res.data.current;
              data.pageSize = res.data.size;
              data.total = res.data.total;
            }
          }
        );
      },
      // 状态显示
      statusSize(fol) {
        if (fol.status == 0) {
          return "提现成功";
        } else if (fol.status == 1) {
          return "审核中";
        } else if (fol.status == 2) {
          return "提现失败";
        } else {
          return "故障";
        }
      },
      // 状态显示颜色
      sizeClass(fol1) {
        if (fol1.status == 0) {
          return "fontcolor";
        } else if (fol1.status == 1) {
          return "fontcolor1";
        } else if (fol1.status == 2) {
          return "fontcolor2";
        }
      },
      // 保留两位小数 超过两位小数的测不变
      decimalClick(num) {
        if (!isNaN(num)) {
          if ((num + "").indexOf(".") != -1) {
            // 判断是否是小数
            if (num.toString().split(".")[1].length < 2) {
              return num.toFixed(2); //判断只有一位小数的自动补全
            } else {
              return num;
            }
          } else {
            return num.toFixed(2);
          }
        }
      },
      // 商户提现
      async WithdrawalAll(params) {
        await requestApi(
          () => {
            return transferDetailApi(params);
          },
          (res) => {
            if (res.code === 200) {
              proxy.tixianVisible = false; // 关闭弹框
              methods.selectPages();
              methods.selectPagesAll();
            }
          }
        );
      },
    };
    return {
      ...toRefs(data),
      searchInput,
      ...methods,
    };
  },
};
</script>
<style lang="less" scoped>
.warp {
  height: 100%;
  .warp-content {
    height: 100%;
  }
}
.page-select {
  width: 100%;
  margin: 0 auto;
}
.page-container {
  width: 100%;
  //margin-top: -28px;
}
.page-foot {
  width: 100%;
  margin-top: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .my-btn {
    height: 28px;
  }
}
.search-input {
  width: 202px;
  margin-right: 4px;
  margin-top: 6px;
}
.fontcolor {
  color: #00cd00;
}
.fontcolor1 {
  color: #0a89f1;
}
.fontcolor2 {
  color: #ee4000;
}
.search-input-class {
  width: 100%;
}
/deep/.el-form-item__label {
  white-space: nowrap;
}
/deep/.el-form-item {
  width: 100%;
}
.fontNav {
  font-size: 18px;
  font-weight: 18px;
}
.wholeClass {
  margin-left: 40px;
}
.zuClass {
  font-size: 10px;
  color: rgb(177, 65, 65);
  margin-right: 200px;
}
</style>
